<template>
  <div class="header-color kid">
    <my-header
      btnRight="mdi-arrow-right"
      :title="'העברת כסף'"
      @clickBtnRight="goBack"
      :avatar="$store.getters.user.avatar"
    />
    <v-container class="body-color">
      <div class="avatar-img d-flex justify-center mt-n16">
        <v-avatar size="128">
          <v-img :src="`/imgs/users/${kid.color}.png`" lazy-src="/person.png" />
        </v-avatar>
      </div>
      <div class="name text-center pt-2">
        {{ kid.name }}
      </div>
      <v-row class="ma-10 d-flex flex-column align-center">
        <v-col cols="12" class="text-center pa-12">
          סך הכל יש לך:
          <div class="kid-balance">
            <span :class="`${balance >= 0 ? 'primary--text' : 'red--text'} `">
              {{ Math.abs(balance) }}
              {{ balance >= 0 ? "" : "-" }}
            </span>
            ₪
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="2" class="d-flex justify-end align-end">
          <span class="error--text">
            <v-btn color="error" large icon @click="isPlus = false">
              <v-icon :class="isPlus ? '' : 'error--text'">mdi-minus</v-icon>
            </v-btn>
            הורדה
          </span>
        </v-col>
        <v-col
          cols="6"
          :class="`center-all input-pay ${
            isPlus ? 'input-pay-plus' : 'input-pay-minus'
          }`"
        >
          <v-text-field
            :class="` ${color + '--text'} ml-2`"
            v-model="numberInput"
            @keypress="onlyNumber"
            :color="color"
            type="number"
            min="0"
            ref="numberInput"
            @focus="onFocus"
            @keypress.enter="submit"
          >
            <template v-slot:append class="d-flex justify-center align-center">
              <span class="ml-5 mt-3">
                <v-icon
                  v-show="!isPlus && numberInput != 0"
                  small
                  color="error"
                >
                  mdi-minus
                </v-icon>
              </span>
              <span class="mt-6">
                <v-icon :color="color" small>mdi-currency-ils</v-icon>
              </span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="2" class="d-flex justify-end align-end">
          <span class="primary--text">
            <v-btn color="primary" large icon @click="isPlus = true">
              <v-icon :class="isPlus ? 'primary--text' : ''">mdi-plus</v-icon>
            </v-btn>
            הוספה
          </span>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <label></label>
          <v-text-field
            :label="`שם הפעולה`"
            v-model="title"
            aria-required="חייב לספר מה קורה..."
          />
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <v-btn
            class="pa-4 gradient-btn"
            block
            rounded
            x-large
            outlined
            @click="submit"
            v-text="'ביצוע'"
          ></v-btn>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>
    <InfoDialog v-if="openInfo" v-model="openInfo" />
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
import InfoDialog from "../components/dialogs/InfoDialog.vue";
export default {
  name: "Kid",
  props: {
    id: { type: String },
  },
  components: { MyHeader, InfoDialog },
  data() {
    const openInfo = localStorage.getItem("showInfo") === null;
    return {
      openInfo: openInfo,
      range: 0,
      numberInput: "0",
      title: "",
      pull: true,
      isPlus: true,
      isNew: true,
    };
  },
  watch: {
    numberInput(v) {
      this.range = this.isPlus ? Math.abs(+v) : Math.abs(-v);
    },
  },
  methods: {
    onFocus() {
      this.numberInput = "";
    },
    handleSlide(e) {
      let el = this.$refs.numberInput;
      el.blur();
      this.numberInput = e;
    },
    goBack() {
      this.$store.commit("action/unset");
      this.$router.go(-1);
    },
    onlyNumber(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode === 45) {
        e.preventDefault();
      } else if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault();
      }
    },
    async submit() {
      if (this.range == 0 || this.numberInput == 0)
        return this.$store.commit("invokeSnack", {
          msg: "צריך לבחור סכום...",
          type: "error",
        });
      this.loading = true;
      let numberInput = Number(this.numberInput).toFixed(2);
      numberInput = this.isPlus ? +numberInput : -numberInput;
      const dispatchMethod = this.isNew ? "actions/create" : "action/update";
      const messageSuccess = this.isNew ? "הועבר בהצלחה!" : "עודכן בהצלחה!";
      try {
        const data = {
          title: this.title,
          amount: numberInput,
          kidId: this.kid._id,
        };
        if (!this.isNew) {
          data["newAction"] = {
            _id: this.action._id,
            title: this.title,
            amount: numberInput,
          };
          data["oldAction"] = this.action;
        }
        await this.$store.dispatch(dispatchMethod, data);
        this.$store.commit("action/unset");
        this.$store.commit("invokeSnack", {
          msg: messageSuccess,
          type: "success",
        });
      } catch (error) {
        this.$store.commit("invokeSnack", {
          msg: "אירעה שגיאה",
          type: "error",
        });
        return;
      }
      this.goBack();
    },
  },
  async beforeCreate() {
    this.loading = true;
    try {
      await this.$store.commit("kid/setById", this.$route.params.id);
      this.loading = false;
    } catch (e) {
      this.$store.commit("invokeSnack", {
        msg: "אירעה שגיאה",
        type: "error",
      });
      this.loading = false;
    }
  },
  async mounted() {
    if (this.action) {
      this.range = Math.abs(this.action?.amount);
      this.numberInput = String(Math.abs(this.action?.amount));
      this.title = this.action?.title;
      this.isPlus = this.action?.amount > 0;
      this.isNew = false;
    }
  },

  computed: {
    color() {
      return this.isPlus ? "primary" : "error";
    },
    action() {
      return this.$store.getters.action;
    },
    actions() {
      return this.$store.getters.actions;
    },
    kid() {
      return this.$store.getters.kid;
    },
    balance() {
      return this.$store.getters.kidBalance(this.kid._id) || this.kid.balance;
    },
  },
};
</script>

<style scoped lang="scss">
.kid > .header {
  height: 160px;
}

.theme--light.v-btn.v-btn--disabled {
  border: solid 0px !important;
  color: var(--primary) !important;
}

::v-deep .input-pay {
  .v-text-field__slot {
    input {
      font-size: xx-large !important;
      text-align: center !important;
      max-height: 100%;
    }
  }
}
::v-deep .input-pay-plus {
  .v-text-field__slot {
    input {
      color: var(--primaryText) !important;
    }
  }
  .v-input__control {
    color: var(--primaryText) !important;
  }
}
::v-deep .input-pay-minus {
  .v-text-field__slot {
    input {
      color: var(--error) !important;
    }
  }
  .v-input__control {
    color: var(--error) !important;
  }
}
</style>
