<template>
  <div>
    <my-dialog v-model="openModal" :max-width="500">
      <template v-slot:content>
        <v-row>
          <v-col class="text-center title">
            <span>
              {{ message }}
            </span>

            <a :href="`tel:+${phone}`">
              {{ phone }}
            </a>
            או
            <a :href="`https://wa.me/${phone}`" target="_blank">
              <v-icon color="success">mdi-whatsapp</v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col cols="12" sm="10" md="10" class="text-center">
            <span>
              <v-checkbox
                label="אל תראה את ההודעה הזאת שוב"
                v-model="doNotShowInfoMessage"
              />
            </span>
            <v-btn color="primary" v-text="'סגור'" @click="closeInfo" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions></template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
export default {
  name: "info-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    info: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    message: "רוצים גם להשתתף בפיילוט (ללא עלות) שלחו הודעה ל-",
    phone: "0527710258",
    err: null,
    doNotShowInfoMessage: false,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeInfo() {
      if (this.doNotShowInfoMessage) {
        localStorage.setItem("showInfo", "false");
      }
      this.openModal = false;
    },
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>
